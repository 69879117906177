.seven-days-calendar .rbc-day-slot .rbc-timeslot-group {
  min-width: 240px;
}
.seven-days-calendar .rbc-time-header {
  padding-left: 26px;
}
.seven-days-calendar .rbc-header {
  min-width: 241px;
}
.seven-days-calendar .rbc-day-slot {
  border-right: 1px solid #dbdaee;
}
.seven-days-calendar .rbc-time-content {
  border-right: none;
  padding-left: 26px;
}
