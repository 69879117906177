/* event-label */
.rbc-event-label {
  display: none;
}
/* full-view */
.rbc-time-view {
  border: none;
}
/* event-box */
.default {
  background-color: #c6f68d;
  border-radius: 0 0 4px 0;
  padding: 0;
  /* width: 96% !important; */
  border: none !important;
  overflow: unset !important;
}
.edit-scheduling-calendar .rbc-time-view .rbc-row {
  height: 90px !important;
}
.rbc-time-view .rbc-row {
  height: 101px !important;
}
/* lines in collumn */
.rbc-timeslot-group {
  flex-flow: nowrap;
  border-bottom: none;
  min-height: 160px;
}
.client-seven-days-calendar .rbc-day-slot .rbc-timeslot-group {
  min-width: 240px;
}
.client-seven-days-calendar .rbc-header {
  background: #fff !important;
}
.client-seven-days-calendar .rbc-header {
  min-width: 241px;
}
.client-seven-days-calendar .rbc-day-slot {
  border-right: 1px solid #dbdaee;
}
.client-seven-days-calendar .rbc-time-content {
  border-right: none;
  padding-bottom: 12px;
}
.client-seven-days-calendar .rbc-time-view {
  /* just for demo */
  padding-right: 40px;
  height: 600px;
  overflow: auto;
}
.client-seven-days-calendar .rbc-time-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}
.client-seven-days-calendar .rbc-time-gutter .rbc-timeslot-group:first-child .rbc-label {
  z-index: 10;
}
/* add margin to 10-11pm */
.rbc-time-slot {
  display: flex;
  align-items: flex-start;
}
/* 10pm 11pm */
.rbc-label {
  margin-top: -7px;
}
/* header */
.rbc-header {
  border-bottom: 1px solid #dbdaee;
  border-left: none !important;
  position: relative;
  background: white !important;
}
/* remove border of header */
.rbc-time-header-content {
  border: none;
}
.client-five-days-calendar .rbc-time-header-content {
  padding-left: 41px;
}
.client-seven-days-calendar.rbc-time-header-content {
  padding-left: 41px;
}
/* style header */
.rbc-day-bg-inner {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #0d092b;
}
/* header bottom part */
.rbc-time-view .rbc-allday-cell {
  display: none;
}
/* header height */

/* class for show 10:00pm */
.rbc-time-content {
  overflow-y: visible;
  border-top: none;
  border-right: 1px solid #dbdaee;
}
.rbc-time-content > * + * > * {
  border-bottom: 1px solid #dbdaee;
}
/* not highlihjt todays column */
.rbc-today {
  background-color: transparent;
}

/* event vertical line */
.vertical-line {
  content: '';
  position: absolute;
  top: -59px;
  left: 48%;
  transform: translateX(-50%);
  width: 1px;
  height: 58px;
  background-color: #4f4350;
}
/* event-orizontal-line */
.horizontal-line {
  content: '';
  position: absolute;
  top: -60px;
  left: 48%;
  transform: translateX(-50%);
  width: 13px;
  height: 1px;
  background-color: #4f4350;
}
/* add manualy 7PM */
.last-time-indicator {
  margin-top: -11px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
}
.today {
  background: #00837d;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  color: #fff !important;
}
.less-than-30 {
  margin-top: -40px;
}
.less-than-40 {
  margin-top: -30px;
}

.event-scheduled {
  background-color: #eaf1ff !important;
}
.event-en-route {
  background-color: #e6d9f9 !important;
}
.event-on-site {
  background-color: #bf9ff3 !important;
}
.event-in-progress {
  background-color: #e3ffc0 !important;
}
.event-completed {
  background-color: #96e191 !important;
}
.event-verified {
  background-color: #26b15c !important;
}
.event-canceled {
  background-color: #ffccc7 !important;
}
/* current time indicator */
.custom-time-indicator {
  background-color: #4f4350 !important;
  height: 2px;
}
.custom-time-indicator::before {
  content: attr(data-current-time);
  position: absolute;
  top: -10px;
  left: -40px;
  background: #4f4350;
  border-radius: 30px;
  padding: 3px 6px 2px 6px;
  color: white;
  font-size: 11px;
  font-weight: 400;
}
.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  background-color: transparent !important;
  color: transparent !important;
  border-color: transparent !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.client-five-days-calendar .rbc-day-slot .rbc-timeslot-group {
  min-width: 240px;
}
.client-five-days-calendar .rbc-header {
  min-width: 241px;
}
.client-five-days-calendar .rbc-day-slot {
  border-right: 1px solid #dbdaee;
}
.client-five-days-calendar .rbc-time-content {
  border-right: none;
  padding-bottom: 12px;
}
.client-five-days-calendar .rbc-time-view {
  /* just for demo */
  padding-right: 40px;
  height: 600px;
  overflow: auto;
}
.client-five-days-calendar .rbc-time-view::-webkit-scrollbar {
  width: 0.4em;
}
.client-five-days-calendar .rbc-time-view::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.client-five-days-calendar .rbc-time-view::-webkit-scrollbar-thumb {
  background: #555;
}
.client-five-days-calendar .rbc-time-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}
.client-five-days-calendar
  .rbc-time-gutter
  .rbc-timeslot-group:first-child
  .rbc-time-slot
  .rbc-label {
  z-index: 10;
}
.holiday {
  background: repeating-linear-gradient(135deg, #eaf1ff, #eaf1ff 6px, #fff 5px, #fff 12px);
}
.client-seven-days-calendar .rbc-current-time-indicator,
.client-five-days-calendar .rbc-current-time-indicator {
  display: none;
}
