/* event-label */
.rbc-event-label {
  display: none;
}
/* full-view */
.rbc-time-view {
  border: none;
}
/* event-box */
.default {
  background-color: #c6f68d;
  border-radius: 0 0 4px 0;
  padding: 0;
  border: none !important;
  overflow: unset !important;
}
.rbc-time-view .rbc-row {
  /* height: 70px !important; */
  height: 61px !important;
}
/* lines in collumn */
.rbc-timeslot-group {
  flex-flow: nowrap;
  border-bottom: none;
  min-height: 160px;
}
/* add margin to 10-11pm */
.rbc-time-slot {
  display: flex;
  align-items: flex-start;
}
/* 10pm 11pm */
.rbc-label {
  margin-top: -7px;
}
/* header */
.rbc-header {
  border-bottom: 1px solid #dbdaee;
  border-left: none !important;
  position: relative;
  background: #fff !important;
}
/* remove border of header */
.rbc-time-header-content {
  border: none;
}
.client-five-days-calendar .rbc-time-header-content {
  padding-left: 67px;
}
.client-seven-days-calendar.rbc-time-header-content {
  padding-left: 39px;
}
/* style header */
.rbc-day-bg-inner {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #0d092b;
}
/* header bottom part */
.rbc-time-view .rbc-allday-cell {
  display: none;
}
/* header height */
.rbc-time-view .rbc-row {
  height: 40px;
}
/* class for show 10:00pm */
.rbc-time-content {
  overflow-y: visible;
  border-top: none;
  border-right: 1px solid #dbdaee;
}
.rbc-time-content > * + * > * {
  border-bottom: 1px solid #dbdaee;
}
/* not highlihjt todays column */
.rbc-today {
  background-color: transparent;
}

/* event vertical line */
.vertical-line {
  content: '';
  position: absolute;
  top: -59px;
  left: 48%;
  transform: translateX(-50%);
  width: 1px;
  height: 58px;
  background-color: #4f4350;
}
/* event-orizontal-line */
.horizontal-line {
  content: '';
  position: absolute;
  top: -60px;
  left: 48%;
  transform: translateX(-50%);
  width: 13px;
  height: 1px;
  background-color: #4f4350;
}
/* add manualy 7PM */
.last-time-indicator {
  margin-top: -11px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
}
.today {
  background: #00837d;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  color: #fff !important;
}
.less-than-30 {
  margin-top: -40px;
}
.less-than-40 {
  margin-top: -30px;
}

.event-scheduled {
  background-color: #eaf1ff !important;
}
.event-en-route {
  background-color: #e6d9f9 !important;
}
.event-on-site {
  background-color: #bf9ff3 !important;
}
.event-in-progress {
  background-color: #e3ffc0 !important;
}
.event-completed {
  background-color: #96e191 !important;
}
.event-verified {
  background-color: #26b15c !important;
}
.event-canceled {
  background-color: #ffccc7 !important;
}
/* current time indicator */
.custom-time-indicator {
  background-color: #4f4350 !important;
  height: 2px;
}
.custom-time-indicator::before {
  content: attr(data-current-time);
  position: absolute;
  top: -10px;
  left: -40px;
  background: #4f4350;
  border-radius: 30px;
  padding: 3px 6px 2px 6px;
  color: white;
  font-size: 11px;
  font-weight: 400;
}
.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  background-color: transparent !important;
  color: transparent !important;
  border-color: transparent !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.holiday {
  background: repeating-linear-gradient(
    135deg,
    #eaf1ff,
    #eaf1ff 6px,
    #fff 5px,
    #fff 12px
  );
}
.rbc-timeslot-group:has(.outside-availability) {
  background: repeating-linear-gradient(
    135deg,
    #eaf1ff,
    #eaf1ff 6px,
    #fff 5px,
    #fff 12px
  );

  display: flex;
  flex-direction: column;
}
.rbc-time-gutter .rbc-timeslot-group {
  background: white;
}
