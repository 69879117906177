/* css file for Toolbar component */

/* main toolbar */
.rbc-toolbar {
  justify-content: flex-start;
}
.rbc-btn-group {
  display: flex;
}
/* toolbar buttons */
.rbc-toolbar button {
  border: none;
  padding: 0;
  padding-right: 10px;
}

.rbc-toolbar button img {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  background: #eff2f9;
}
.rbc-toolbar button:hover {
  background-color: transparent;
  cursor: pointer;
}
/* toolbar title */
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 0;
}
.five-days-calendar .rbc-time-header {
  padding-left: 24px;
}
.five-days-calendar .rbc-time-content {
  padding-left: 26px;
}
.rbc-day-slot .rbc-time-slot {
  border-top: none;
}
