.rbc-addons-dnd {
  padding-right: 16px;
}
.rbc-addons-dnd .rbc-current-time-indicator {
  background-color: #0d092b !important;
  height: 2px;
  z-index: 102;
}
.rbc-row-resource {
  /* border: 1px solid #dbdaee; */
  border: none;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}
.rbc-addons-dnd .rbc-header {
  border-bottom: none;
  padding: 0;
  padding-left: 1px;
  height: 61px;
  overflow: visible;
}

.CaregiversBox {
  border: 1px solid #dbdaee;
  border-bottom: none;
  background-color: #eff2f9;
}

.Scheduling .rbc-day-slot {
  min-width: 291px;
}
.Scheduling .rbc-header {
  min-width: 292px;
  height: 100%;
}

.Scheduling .rbc-time-content {
  border-right: none;
}
.Scheduling .rbc-time-view-resources {
  overflow-x: visible;
}
.edit-scheduling-calendar .rbc-time-view-resources {
  max-height: calc(100vh - 200px);
}
.edit-scheduling-calendar .rbc-day-slot {
  min-width: 292px;
}
.edit-scheduling-calendar .rbc-header {
  min-width: 293px;
}
.Scheduling .rbc-time-header {
  z-index: 100;
  background: white;
  position: relative;
  padding-left: 69px;
  overflow: hidden;
  margin-right: 0 !important;
  min-height: 61px;
}
.Scheduling .rbc-time-content {
  /* left: 56px; */
  max-height: calc(100vh - 290px);
  overflow: auto;
  padding-left: 30px;
}

.Scheduling .rbc-time-content::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
}

.Scheduling .rbc-time-content::-webkit-scrollbar {
  height: 10px;
}

.Scheduling .rbc-time-content::-webkit-scrollbar-thumb {
  background-color: #6c6c6c;
  border-radius: 5px;
}

.Scheduling .rbc-time-content::-webkit-scrollbar-thumb:hover {
  background-color: #6c6c6c;
}

/* Change the style of the vertical scrollbar */

.Scheduling .rbc-time-content::-webkit-scrollbar:vertical {
  width: 10px;
}

.Scheduling .rbc-time-gutter {
  z-index: 101;
}
.rbc-addons-dnd .custom-time-header-scheduling {
  /* min-height: 257px; */
  min-height: 233px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-label.rbc-time-header-gutter {
  border: none;
}

.rbc-addons-dnd .custom-time-indicator-scheduling::before {
  content: attr(data-current-time);
  position: absolute;
  top: -10px;
  left: -75px;
  background: #fff;
  border-radius: 4px;
  padding: 2px 0 2px 6px;
  color: #2c2d2e;
  font-size: 10px;
  font-weight: 400;
  box-shadow: 0 0 12px 0 #2c2d2e29;
  z-index: 1000;
}

.rbc-addons-dnd-resizable {
  width: 100%;
}
.arrival {
  background: linear-gradient(0deg, rgba(43, 119, 255, 0.2), rgba(43, 119, 255, 0.2)) !important;
  display: none !important;
  width: 95% !important;
}
.arrivalTime {
  display: none !important;
  background: none !important;
  width: 95% !important;
  left: 0 !important;
}
.lunchBreak {
  background: repeating-linear-gradient(
    135deg,
    #ffebce,
    #ffebce 6px,
    #fff 5px,
    #fff 12px
  ) !important;
  color: black !important;
}
.ptoCalendarEvent {
  background: transparent !important;
  color: black !important;
}
.dnd-event {
  background-color: #96e191;
  border-radius: 0 0 4px 0;
  padding: 0;
  /* width: 95% !important; */
  border: none !important;
  overflow: unset !important;
  /* left: 0 !important; */
}

.not-allocated {
  background-color: #ffccc7 !important;
  margin-left: 1px;
}
.without-caregiver {
  background-color: #eaf1ff !important;
}
.rbc-time-gutter .rbc-time-slot {
  background: transparent !important;
}
.Scheduling .rbc-timeslot-group {
  background: repeating-linear-gradient(135deg, #eaf1ff, #eaf1ff 6px, #fff 5px, #fff 12px);
  display: flex;
  flex-direction: column;
}
.Scheduling .rbc-timeslot-group .rbc-time-slot {
  border-top: none;
}

.Scheduling .rbc-time-gutter .rbc-timeslot-group {
  background: white;
}
.Scheduling .rbc-event.rbc-selected {
  background-color: #96e191;
}
.rbc-addons-dnd-resizable {
  height: 100%;
}
.rbc-label.rbc-time-header-gutter {
  display: none;
}
.Scheduling .rbc-label {
  margin-top: -3px;
}
.MuiTypography-root {
  cursor: default;
}
.rbc-addons-dnd-dragged-event.is-dragging {
  background: 'none' !important;
  background-color: transparent !important;
}
.rbc-addons-dnd-dragged-event.is-dragging .event-before-box {
  display: none;
}
.rbc-addons-dnd-dragged-event.is-dragging .timeContent {
  display: none;
}
.rbc-addons-dnd-drag-preview .arrivalWindow {
  display: none;
}
.rbc-addons-dnd-dragged-event.is-dragging .travelTime {
  display: none;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
}
.expandedHeader {
  transition: all 0.5s ease-out;
}
