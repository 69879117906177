/* event-label */
.rbc-event-label {
  display: none;
}
/* full-view */
.rbc-time-view {
  border: none;
}
/* event-box */
.care-program-calendar {
  background-color: #eff2f9;
  border-radius: 0 0 4px 0;
  padding: 0;
  width: 96% !important;
  border: none !important;
  overflow: unset !important;
}
/* event-box */
.care-program-calendar.rbc-event.rbc-selected {
  background-color: #eff2f9;
}
/* lines in collumn */
.rbc-timeslot-group {
  flex-flow: nowrap;
  border-bottom: none;
  min-height: 160px;
}

/* add margin to 10-11pm */
.rbc-time-slot {
  display: flex;
  align-items: flex-start;
}
.rbc-label {
  margin-top: -7px;
}
/* header */
.rbc-header {
  border-bottom: 1px solid #dbdaee;
  border-left: none !important;
  position: relative;
}
/* remove border of header */
.rbc-time-header-content {
  border: none;
}
/* style header */
.rbc-day-bg-inner {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #0d092b;
}
/* header bottom part */
.rbc-time-view .rbc-allday-cell {
  display: none;
}
/* header height */
/* class for show 10:00pm */
.rbc-time-content {
  overflow-y: visible;
  border-top: none;
  border-right: 1px solid #dbdaee;
}
.rbc-time-content > * + * > * {
  border-bottom: 1px solid #dbdaee;
}
/* not highlihjt todays column */
.rbc-today {
  background-color: transparent;
}

/* event vertical line */
.vertical-line {
  content: '';
  position: absolute;
  top: -59px;
  left: 48%;
  transform: translateX(-50%);
  width: 1px;
  height: 58px;
  background-color: #4f4350;
}
/* event-orizontal-line */
.horizontal-line {
  content: '';
  position: absolute;
  top: -60px;
  left: 48%;
  transform: translateX(-50%);
  width: 13px;
  height: 1px;
  background-color: #4f4350;
}
/* add manualy 7PM */
.last-time-indicator {
  margin-top: -11px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
}
.custom-time-indicator-care-program {
  display: none;
}

.seven-days-care-program-calendar .rbc-time-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}
.seven-days-care-program-calendar .rbc-label {
  z-index: 10;
}
