@use './global/styles/reset';
@use './global/styles/base';
@use './shared/styles/ui';

.App {
  @include ui.mainContainer;
}
.UnAuthView {
  display: flex;
  justify-content: center;
  align-items: center;
}
.UnAuthBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
