/* css file for Toolbar component */

/* main toolbar */
.toolbar {
  justify-content: flex-start;
}
.buttonGroup {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}
/* toolbar buttons */
.toolbar .calendarButtons {
  border: none;
  padding: 0;
  padding-right: 10px;
  background: none;
}
/* toolbar buttons */
.toolbar .calendarButtons {
  width: 30px;
  height: 30px;
  padding-right: 0;
}

.toolbar .calendarButtons img {
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 50%;
  background: #eff2f9;
}
.toolbar .calendarButtons:hover {
  background-color: transparent;
  cursor: pointer;
}
/* toolbar title */
.toolbar .rbc-toolbar-label {
  flex-grow: 0;
}
